import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import AppRoutes from './AppRoutes';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';

const App = props => {
  // wrapperRef = createRef();

  // contentRef = createRef();
  const history = useNavigate();
  const { actions, application, session, location } = props;
  return (
    <div
      className="realm--root"
    >
      <Header actions={actions} application={application} session={session} history={history}
      // pathname={location.pathname} 
      />
      <div className="realm--top-container" >
        <div className="realm--page-content" >
          <AppRoutes
            actions={actions}
            application={application}
            session={session}
            history={history}
          />
        </div>
        <Footer application={application}/>
      </div>
    </div>
  );
}

App.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  location: PropTypes.object,
  session: PropTypes.object
};

export default App;
