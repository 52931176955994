import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as storage from '../../persistence/storage';
import LoadingIndicator from '../../components/LoadingIndicator';
import localization from '../../services/Localization';

const Logout = props => {


  useEffect(() => {
    setTimeout(() => {
      storage.clear();
      props.actions.clearApplication();
      props.actions.clearSession();
      window.location.href = '/';
    }, 500);
  }, []);



  return (
    <div className="realm--logout-screen">
      <div style={{margin: 40, paddingTop: 200}}>
        <h3>{localization.page_logout}</h3>
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
        <div className="realm--signing-out">{localization.page_logout_signingout} ...</div>
      </div>
    </div>
  );
}

export default Logout;
