import React from 'react';
import localstring from '../services/Localization';

const ContactUs = () => (
  <div id="Contact" className="realm--contact-panel">
    {localstring.general_contact_us}
    <div className="realm--contact-title">
      {localstring.contact_form_000}
    </div>
    <div className="realm--contact-text">
      {localstring.contact_form_001}  <a href="mailto:realminformation.realm@gmail.com">{localstring.email_us}</a>
    </div>
    <div className="realm--contact-form" />
  </div>
);

export default ContactUs;
