import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography
} from '@material-ui/core';

// import Localizer from './Localizer';
import logoImage from '../images/logos/REALM_white_TM.png';
import localstring from '../services/Localization';
import Languages from '../services/LanguageList';
import AccountMenu from './Menu-Account';
import ToolsMenu from './Menu-Tools';

const styles = () => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  languageButton: {
    marginBottom: 8,
    marginLeft: 8,
    paddingTop: 0,
    paddingBottom: 3,
    fontSize: 10,
    letterSpacing: 0.5,
    borderRadius: 0,
    textTransform: 'uppercase',
    border: '2px white solid',
    '&:hover': {
      color: '#d2763d',
      textDecoration: 'none'
    }
  },
  socialBar: {
    paddingTop: 4,
    display: 'inline-block'
  },
  topSocial: {
    color: 'white',
    fontSize: 24,
    marginLeft: 12,
    position: 'relative',
    top: 4,
    '&:hover': {
      color: '#d2763d',
      textDecoration: 'none'
    }
  },
  menu: {
    marginTop: 32
  },
  menuButton: {
    color: 'white',
    fontSize: 14,
    fontWeight: 300,
    textTransform: 'uppercase',
    marginRight: 12,
    marginTop: 2,
    '&:hover': {
      color: '#d2763d',
      textDecoration: 'none'
    }
  }
});

class Header extends Component {
  state = {
    environment: 'local',
};

  scrollToAnchor = (anchor) => {
    document.getElementById(anchor).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  };

  getCurrentLanguage = () => {
    const { locale } = this.props.application;
    const lang = Languages.filter((l) => l.Short_Name === locale);
    return lang[0].Full_Name;
  };

  render() {
    const { classes, pathname } = this.props;
    // const { anchorEl } = this.state;
    // const currentLanguage = this.getCurrentLanguage();
    return (
      <AppBar position="fixed" className="realm--header">
        <Toolbar>
          <Typography
            variant="title"
            color="inherit"
            noWrap
            style={{ flex: 1 }}
          >
            <div className="realm--header-logo-box">
              <Link to="/">
                <img src={logoImage} alt="Realm logo" />
              </Link>
              <div className="realm--header-env-name">{sessionStorage.getItem('currentAPIEnv')}</div>
            </div>
          </Typography>
          {this.props.session.token && (
                <span className="realm--anchor-nav">
                <ToolsMenu {...this.props} />
              </span>      
          )
          }
          <span className="realm--anchor-nav">
            <AccountMenu {...this.props} />
          </span>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  // actions: PropTypes.object,
  application: PropTypes.object,
  classes: PropTypes.object.isRequired,
  pathname: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Header);
