import React, {useEffect, useState} from 'react';
import api from '../../api';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';

const EmailLogs = props => {
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const getData = async() => {
    const response = await api.get(`${props.application.apiRoot}/log/emails`);
    debugger;
    setLogData(response.data.data.logs);
  };

  useEffect(() => {
    // load the data
    setIsLoading(true);
    getData();
    setIsLoading(false);
  },
    [])


  return (
    <div className="realm--page-with-title">
    <PageTitle title="Email Logs"  />
    {isLoading && (
      <LoadingIndicator />
    )}
    {!isLoading && (
      <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>From</TableCell>
            <TableCell>BCC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logData.map(row => (
            <TableRow>
             <TableCell>{row.createdAt}</TableCell>
            <TableCell>{row.emailName}</TableCell>
            <TableCell>{row.to}</TableCell>
            <TableCell>{row.subject}</TableCell>
            <TableCell>{row.from}</TableCell>
            <TableCell>{row.bcc}</TableCell>             
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </>
    )}
    </div>
  );
}

export default EmailLogs;