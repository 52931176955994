import LocalizedStrings from 'react-localization';

const localstring = new LocalizedStrings({
  English: {
    general_agents: 'Agents',
    general_clients: 'Clients',
    general_contact_us: 'Contact Us',

    signin: 'Sign in',
    login_username: 'Username',
    login_password: 'Password',

    contact_form_000: 'Your REALM Begins Now',
    contact_form_001: 'Let us help you get there. ',
    email_us: 'Email us today.',

    menu1_user_account: 'Account',
    menu1_user_profile: 'Profile: ',
    menu1_user_logout: 'Sign out',

    nav_marketing_contact: 'Contact',
    nav_marketing_worldof: 'World of Realm',
    nav_marketing_global: 'Global Network',

    nav_menu_dashboard: 'Dashboard',
    nav_menu_dashboard_item1: 'Dashboard',

    nav_menu_blog: 'Blog',

    nav_menu_concierge: 'Concierge',
    nav_menu_concierge_item1: 'Contact an Agent',
    nav_menu_concierge_item2: 'Contact a Brokerage',

    contact_us: 'Contact Us',
    contact_us_title: 'How can we help?',
    contact_to: 'Send to',
    contact_to_help: 'Help/Support',
    contact_to_suggestions: 'To the REALM Team',
    contact_re: 'Contacting you about...',
    contact_re_feedback: 'Feedback/Suggestions',
    contact_re_tech: 'Technical Support',
    contact_re_account: 'Help with my account',
    contact_re_other: 'Other',
    contact_message: 'Your message',
    contact_send: 'Send',
    contact: 'Contact',
    message_sent: 'Your message was sent',
    message_error: 'Sorry, there was an error. Please try again later.',

    copyright: 'Copyright 2018, Realm, LLC',
    footer_terms: 'Terms & Conditions',
    footer_cookies: 'Cookies Policy',
    footer_privacy: 'Privacy Policy',
    footer_confidentiality: 'Confidentiality Policy',

    page_button_join: 'Join Realm',
    page_button_learn_more: 'Learn More',

    page_404_header: '404 Error',
    page_404_message:
      'Realm is timeless but the page you requested is not. Start your journey below.',
    page_404_cta: 'Explore',

    page_logout: 'Thank you for visiting REALM Global',
    page_logout_signingout: 'Signing out',

    page_home_000: 'Your Realm is Endless',
    page_home_001: 'Relationship powered, lifestyle inspired.',

    page_home_002: 'YOUR REALM IS',

    page_home_003: 'The Future in Luxury',

    page_home_004:
      'The world’s most trusted and respected independent brokers and agents partnering worldwide.',

    page_home_005:
      'REALM is the new way for luxury Real Estate creating an engagement that enhances relationships between partner brokerages and clients through experiences that make a lifetime rather than search criteria.',

    page_home_006:
      'REALM serves the network of agents by providing the most exclusive, secure, confidential, and technology enhanced relationship optimizing the luxury listing network between clients and agents. ',

    page_home_007: 'YOUR REALM IS',

    page_home_008: 'Exclusive ',

    page_home_009:
      'The REALM experience leverages the power of the global brokerage network,',

    page_home_010:
      'to match qualified buyers to properties based on lifestyle and passion rather than criteria. A curated luxury lifestyle digital content platform with integration of Machine Learning and Artificial Intelligence. ',

    page_home_011:
      "REALM collects, secures, and repositions your client's data feeds to improve the holistic real estate experience – for each individual broker and its collective member-base. REALM’s best-in-class technology is revolutionary for the Real estate market providing the highest level of security and protection of encrypted data. Allowing your clients REALM to be limitless.",

    page_home_012: ' Global',

    page_home_013: 'NETWORK',

    page_home_014:
      'REALM’s Global Partnership Network is the most collective and innovative group of brokerages and partners.',

    page_home_015:
      "REALM has pre-negotiated arrangements with our network partners that can get you and your client's access to affiliate events, reciprocal perks, discounted memberships, and a variety of concierge services worldwide.",
    page_home_016: 'AFFINITY PARTNERSHIP',

    page_home_017: 'Program',

    page_home_018:
      'REALM’s Affinity Partnership Program was oriented specifically around facilitating bespoke experiences for agents, brokerages, and their clients.',
    page_home_019:
      'As a REALM member, you’ll help inform both local and international event strategies, while also having access to local insights and affinity partners required to bring your own unique event experiences to life. ',

    page_home_020: 'YOUR REALM IS',

    page_home_021: 'Effortless',

    page_home_022:
      'REALM is the world’s most elite global alliance of independent brokers, partnering together to create a true global network for luxury real estate.',
    page_home_023:
      'REALM is the new way for luxury brokerage partners to create relationships with clients through lifestyle experience rather than search criteria.',
    page_home_024: 'YOUR REALM IS',

    page_home_025: ' Endless',

    page_home_026:
      'REALM is the new way for luxury brokerage partners to create relationships with clients through lifestyle experience rather than search criteria.',
    page_home_027: 'YOUR REALM IS',
    page_home_028: ' Stressless',

    page_home_029:
      'Curated luxury lifestyle enhanced with machine learning and artificial intelligence.',
    page_home_030: 'Total Value of REALM Exclusive Listings',

    page_home_031:
      'Integer eu ornare purus. Proin luctus nisi a lorem volutpat fringilla. Praesent a augue eu orci pulvinar malesuada. Praesent malesuada ex vitae metus interdum, ac finibus quam placerat. Nullam nec leo a augue iaculis ornare ut in nisl. Suspendisse potenti. Morbi imperdiet, nulla non placerat laoreet, mi leo lobortis ex, eu gravida est leo non mi.',

    page_home_032:
      'Representing one of the most exclusive listings in the world is a privilege and it takes an agent that truly understands the clientele.  I’m fortunate to have 50 clients in my personal database that are qualified to purchase this property and I know that I am connected to the trusted advisors of at least 50 more through the real estate professionals of the REALM.',

    page_home_033: '', // agent name

    page_home_034:
      'The REALM has transformed how I present my listings to the world.  While most real estate networks focus on a broadcast strategy without any kind of targeted marketing or collaboration amongst the brokers, the REALM experience helps me to target highly qualified buyers through their trusted real estate advisors.  Based on insights that no other network can offer, whether buying or selling, the client has a far more custom experience that is completely focused on their lifestyle and priorities.',

    page_home_035: '', // agent name

    page_home_036:
      'I was representing a new development in our marketplace in collaboration with a luxury hotel chain.  Through the REALM I was able to offer the developer proof of concept and pricing strategy by initially offering the product to members of the REALM.  We sold through 40% of the inventory through the REALM and hit the (mass) market with the momentum necessary to achieve record price levels.',

    page_home_037: '', // agent name

    page_client_000: 'Your Realm is Endless',

    page_client_001: 'Relationship powered, lifestyle inspired.',

    page_client_002: 'YOUR REALM IS',

    page_client_003: 'Sophisticated',

    page_client_004:
      'REALM is changing how real estate is discovered, modernizing the real estate experience with aggregation of data at a global scale',

    page_client_005:
      'REALM collects, secures, and repositions your data feeds to improve the holistic real estate experience – for each individual broker and its collective member-base.',
    page_client_006: 'REALM can:',

    page_client_007:
      'Provide Predictive lead scoring and listing matching technology.',
    page_client_008:
      'Data Concierge Service to validate data and advise on its optimization.',
    page_client_009:
      'Private Client Portal enables communication between agents and clients within the platform.',
    page_client_010: 'Database inscription to ensure member data is secure.',

    page_client_011: 'YOUR REALM IS',

    page_client_012: 'Exclusive',

    page_client_013:
      'The REALM experience leverages the power of the global brokerage network,',

    page_client_014:
      'to match qualified buyers to properties based on lifestyle and passion rather than criteria. A curated luxury lifestyle digital content platform with integration of Machine Learning and Artificial Intelligence. ',
    page_client_015:
      'REALM’s best-in-class technology is revolutionary for the Real estate market providing the highest level of security and protection of encrypted data. Allowing your REALM to be limitless.',
    page_client_016: 'YOUR REALM IS',

    page_client_017: 'Timeless',

    page_client_018: 'REALM is a collection of the best minds in Real Estate.',
    page_client_019:
      ' REALM is the new way for luxury Real Estate creating an engagement that reaches clients like never before.',
    page_client_020:
      '“In a world where almost, every aspect of our lives is somehow tethered to technology, experiences that engage our bodies, our senses and our souls are at a premium. Digital is what we’ve become but visceral is what we crave.”',

    page_client_021: '- Doug Stephens, Retail Prophet',

    page_client_022: 'YOUR REALM IS',

    page_client_023: 'Effortless',

    page_client_024:
      'REALM’s network of global partners provides clients an effortless ',
    page_client_025:
      ' luxurious experience, while focused on complete anonymity and exclusivity.',

    page_client_026:
      'Ensuring that the buying and selling experience is delightful, exciting, and overall the high-end luxury each client deserves.',

    page_client_027: 'YOUR REALM IS',

    page_client_028: 'Endless',

    page_client_029:
      'REALM is the new way for luxury brokerage partners to create relationships with clients through lifestyle experience rather than search criteria.',
    page_client_030: 'YOUR REALM IS',

    page_client_031: 'Stressless',

    page_client_032:
      'Curated luxury lifestyle enhanced with machine learning and artificial intelligence.',
    page_client_033: 'Total Value of REALM Exclusive Listings',

    page_client_034:
      'Integer eu ornare purus. Proin luctus nisi a lorem volutpat fringilla. Praesent a augue eu orci pulvinar malesuada. Praesent malesuada ex vitae metus interdum, ac finibus quam placerat. Nullam nec leo a augue iaculis ornare ut in nisl. Suspendisse potenti. Morbi imperdiet, nulla non placerat laoreet, mi leo lobortis ex, eu gravida est leo non mi.',

    page_client_035:
      'Representing one of the most exclusive listings in the world is a privilege and it takes an agent that truly understands the clientele.  I’m fortunate to have 50 clients in my personal database that are qualified to purchase this property and I know that I am connected to the trusted advisors of at least 50 more through the real estate professionals of the REALM.',

    page_client_036: '', // agent name

    page_client_037:
      'The REALM has transformed how I present my listings to the world.  While most real estate networks focus on a broadcast strategy without any kind of targeted marketing or collaboration amongst the brokers, the REALM experience helps me to target highly qualified buyers through their trusted real estate advisors.  Based on insights that no other network can offer, whether buying or selling, the client has a far more custom experience that is completely focused on their lifestyle and priorities.',

    page_client_038: '', // agent name

    page_client_039:
      'I was representing a new development in our marketplace in collaboration with a luxury hotel chain.  Through the REALM I was able to offer the developer proof of concept and pricing strategy by initially offering the product to members of the REALM.  We sold through 40% of the inventory through the REALM and hit the (mass) market with the momentum necessary to achieve record price levels.',

    page_client_040: '', // agent name

    page_about_001: 'About',

    page_about_002: 'Realm',

    page_about_003: 'Founding',

    page_about_004: 'Partners',

    page_about_005:
      'REALM brings together the world’s most trusted and respected independent brokers and agents worldwide. Powered by the most dynamic cloud, software and web-based intelligent platform. Providing the finest exclusive listings globally with unmatched personal service and market performance.',

    page_about_006: 'Broker Owned',

    page_about_007:
      'A progressive and collective Real Estate organization managed by the greatest minds in the industry.',

    page_about_008: 'Lifestyle Inspired',

    page_about_009:
      'A holistic brand experience that’s built around the unique lifestyles and personal interests of each client.',

    page_about_010: 'Global Network',

    page_about_011:
      'Hand-selected group of localized agents and brokerages from all across the globe.',

    page_about_012: 'Pacific Union',

    page_about_013:
      'Leading luxury real estate brand in California. Founded in 1975, Pacific Union was acquired by CEO Mark A. McLaughlin in 2009. Since then, McLaughlin has grown the company from $2.1 billion in sales volume 2009 to $14.13 billion in 2017.  With 50+ offices and more than 1,700 real estate professionals in the Bay Area, greater Los Angeles, and the Lake Tahoe region -- more than any other independent real estate firm – Pacific Union International offers a full range of personal and commercial real estate services, including buying, selling, and relocation.',

    page_about_014: 'Jackson Hole Real Estate Associates',

    page_about_015:
      'An organization comprised of experienced, successful and dedicated real estate professionals, with a collection of some of the most unique and sought-after real estate properties in the world. As the exclusive affiliate of Christie’s International Real Estate, Jackson Hole Real Estate Association provides exposure to a worldwide audience through 140 global affiliates with 32,000 real estate professionals in more than 46 countries, with transactions of $113 billion annually.',

    page_about_016: 'Brown Harris Stevens',

    page_about_017:
      'Quintessential luxury residential real estate firm, with a history of leadership and a culture of professionalism and discretion dating back to 1873. Offices located in New York City, The Hamptons, Palm Beach, and Miami. Brown Harris Stevens brokers are exceptional - leading the field with the quality of their work ethic, their outstanding success rate and the sheer quantity of their transactions. Averaging more than 20 years of experience in residential real estate, by far the highest in the industry, and have marketing and negotiating skills that produce the highest rate of success in arranging transactions for their clients.',

    page_maintenance_001: 'Your REALM will return shortly.',

    page_maintenance_002:
      'We apologize for the inconvenience. We are performing scheduled maintenance and will be back up on'
  },
  'Simplified Chinese': {
    general_agents: '代理',
    general_clients: '客户端',

    login: '登录',
    login_username: '用户名',
    login_password: '密码',

    logo_back_office: '后台办公室',

    menu1_user_account: '帐户',
    menu1_user_profile: '轮廓: ',
    menu1_user_logout: '登出',

    nav_menu_dashboard: '仪表板',
    nav_menu_dashboard_item1: '仪表板',

    nav_menu_matching: '匹配',
    nav_menu_matching_item1: '匹配结果',
    nav_menu_matching_item2: '测试门户',

    nav_menu_brokerages: '券商',
    nav_menu_brokerages_item1: '名单',
    nav_menu_brokerages_item2: '加',
    nav_menu_brokerages_item3: '摄取',

    nav_menu_agents: '代理',
    nav_menu_agents_item1: '名单',
    nav_menu_agents_item2: '加',
    nav_menu_agents_item3: '摄取',

    nav_menu_clients: '客户端',
    nav_menu_clients_item1: '名单',
    nav_menu_clients_item2: '加',
    nav_menu_clients_item3: '摄取',

    nav_menu_properties: '属性',
    nav_menu_properties_item1: '名单',
    nav_menu_properties_item2: '加',
    nav_menu_properties_item3: '摄取',

    nav_menu_blog: '博客',

    nav_menu_concierge: '门房',
    nav_menu_concierge_item1: '联系代理商',
    nav_menu_concierge_item2: '联系经纪人e',

    nav_menu_administration: '行政',
    nav_menu_administration_item1: '用户',
    nav_menu_administration_item2: '摄取数据',
    nav_menu_administration_item3: '日志',
    nav_menu_administration_item4: '手动日志'
  },
  Arabic: {
    login: 'تسجيل الدخول',
    login_username: 'اسم المستخدم',
    login_password: 'كلمه السر',

    logo_back_office: 'مكتب خلفي',

    menu1_user_account: 'الحساب',
    menu1_user_profile: 'الملف الشخصي: ',
    menu1_user_logout: 'الخروج',

    nav_menu_dashboard: 'لوحة القيادة',
    nav_menu_dashboard_item1: 'لوحة القيادة',

    nav_menu_matching: 'مطابقة',
    nav_menu_matching_item1: 'نتائج المطابقة',
    nav_menu_matching_item2: 'اختبار البوابة',

    nav_menu_brokerages: 'شركات السمسرة',
    nav_menu_brokerages_item1: 'قائمة',
    nav_menu_brokerages_item2: 'إضافة',
    nav_menu_brokerages_item3: 'ابتلاع',

    nav_menu_agents: 'عملاء',
    nav_menu_agents_item1: 'قائمة',
    nav_menu_agents_item2: 'إضافة',
    nav_menu_agents_item3: 'ابتلاع',

    nav_menu_clients: 'عملاء',
    nav_menu_clients_item1: 'قائمة',
    nav_menu_clients_item2: 'إضافة',
    nav_menu_clients_item3: 'ابتلاع',

    nav_menu_properties: 'الخصائص',
    nav_menu_properties_item1: 'قائمة',
    nav_menu_properties_item2: 'إضافة',
    nav_menu_properties_item3: 'ابتلاع',

    nav_menu_blog: 'مدونة',

    nav_menu_concierge: 'خدمات الاستقبال والإرشاد',
    nav_menu_concierge_item1: 'اتصل بوكيل',
    nav_menu_concierge_item2: 'اتصل بالسمسرة',

    nav_menu_administration: 'الادارة',
    nav_menu_administration_item1: 'المستخدمين',
    nav_menu_administration_item2: 'ادخال البيانات',
    nav_menu_administration_item3: 'سجلات',
    nav_menu_administration_item4: 'سجلات يدوية'
  }
});

export default localstring;
