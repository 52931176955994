import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
  withStyles
} from '@material-ui/core';

import ErrorDialog from '../../components/ErrorDialog';
import LoadingIndicator from '../../components/LoadingIndicator';
import api from '../../api';
import  '../../constants';
import BackgroundImage from '../../images/photos/collage7.jpg';
import { API_URLS, API_IMAGE_URLS, ENV_COLORS } from '../../constants';

import styles from './styles';

class Login extends Component {
  state = {
    // errorMessage: '',
    // isError: false,
    isLoading: false,
    isValid: false,
    password: '',
    email: '',
    loginError: false,
  };

  componentDidMount() {
    api.get(`${this.props.application.apiRoot}/info/system-settings/`); 
    const env = window.location.href.includes('localhost') ? 'local' : 'prod';
    const newBaseUrl = API_URLS[env];
    const newImageBaseUrl = API_IMAGE_URLS[env];
    const payload = {
      env,
      newBaseUrl,
      newImageBaseUrl
    }
    // for below, see reducers in reducers/application
    this.props.actions.setEnvironment(payload);

    // set header color
    const rootDoc = document.documentElement;
    rootDoc.style.setProperty('--header-color1', ENV_COLORS[env]);
  }

  componentDidUpdate(p, np) {
    if (this.props.session.authenticationFailed && this.state.loginError === false) {
      this.setState({
        loginError: this.props.session.errorMessage,
        isLoading: false,
      }, () => {
        alert(`Sign in failed: ${this.state.loginError}`);
        this.props.actions.retryAuthentication();
        this.setState({loginError: false});
      })

    }
  }

  validateForm = () => this.validateEmail() && this.state.password.length > 0;

  // for now -- need better validation
  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.email);
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.id]: event.target.value
      },
      () => {
        this.setState({ isValid: this.validateForm() });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true }, () => {
      const authenticationData = {
        username: this.state.email,
        password: this.state.password
      };
      this.props.actions.authenticate(authenticationData, this.props.application.apiRoot);
    });

  };

  handleClose = () => {
    this.props.actions.retryAuthentication();
    this.setState({
      email: '',
      password: '',
      isLoading: false,
    });
  };

  render() {
    const { classes, session } = this.props;

    return (
      <Fragment>
        {/* <AppBar className={classes.appBar}>Sign In</AppBar> */}
        <div className={classes.page} style={ { background: `url(${BackgroundImage}) center center` }} >
          <div className={classes.loginPanel}>
            <h3>Concierge Sign In:</h3>
          <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email" bsSize="large" style={{marginBottom: 18}}>
                        <TextField
                            autoFocus
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="Email"
                            id="email"
                        />
                    </FormGroup>

                    <FormGroup controlId="password" bsSize="large" style={{marginBottom: 18}}>

                        <TextField
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                            placeholder="Password"
                            id="password"
                        />
                    </FormGroup>

                    <div>
                      {!this.state.isLoading && this.validateForm() && (
                        <Button
                          variant='contained'
                          color='primary'
                          style={{marginBottom: 20}}
                          onClick={this.handleSubmit}
                        >Sign In</Button>
                      )}
                      {this.state.isLoading && (
                        <LoadingIndicator msg={'Signing In'} message={'Signing In'}/>
                      )

                      }
                    {/* <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Sign In"
                        loadingText="Signing in…"
                    /> */}
                    </div>
                    <Link to="/login/reset" >Forgot password?</Link>
                </form>
          </div>
        </div>
        <ErrorDialog
          handleClose={this.handleClose}
          message={session.errorMessage}
          open={session.authenticationFailed}
        />
      </Fragment>
    );
  }
}

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
