import React, { Component } from 'react';
import BlogList from '../../components/BlogList';
import PageTitle from '../../components/PageTitle';

class Blog extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="realm--page-with-title">
        <PageTitle title="Blog" />
        <div className="realm--page-for-copy">
          <BlogList
            categories={[17]}
            count={20}
            grid
            title=""
            view="card"
          />
        </div>
      </div>
    );
  }
}

export default Blog;
