import * as React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckBox, CheckBoxOutlineBlankSharp } from '@material-ui/icons';

import Logout from '@material-ui/icons/ExitToApp';
import api from '../api';
import { API_URLS, ENV_COLORS, API_IMAGE_URLS } from '../constants';

const AccountMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentEnv, setCurrentEnv] = React.useState(sessionStorage.getItem('currentAPIEnv') || 'prod');
  const [myInitials, setMyInitials] = React.useState('RC');
  const open = Boolean(anchorEl);
  const p = props;
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    // test!
    api.get(`${props.application.apiRoot}/info/system-settings/`)
  },
    [props.application.apiRoot])

    React.useEffect(() => {
     let initials = 'RC';
     if (props.session.currentUser) {
      initials = `${props.session.currentUser.firstName.charAt(0)}${props.session.currentUser.lastName.charAt(0)}`
     }
     setMyInitials(initials);
    },
      [props.session.currentUser])

  const handleSetEnv = (env) => {
    const a = API_URLS;
    const newBaseUrl = API_URLS[env];
    const newImageBaseUrl = API_IMAGE_URLS[env];
    const payload = {
      env,
      newBaseUrl,
      newImageBaseUrl
    }
    // for below, see reducers in reducers/application
    props.actions.setEnvironment(payload);
    setCurrentEnv(env);

    // set header color
    const rootDoc = document.documentElement;
    rootDoc.style.setProperty('--header-color1', ENV_COLORS[env]);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar style={{height: 40, width: 40, fontSize: 16, padding: 6, marginBottom: 8, backgroundColor: 'rebeccapurple'}}>{myInitials}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {padding: 12},
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            pl: 12,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        style={{padding: 12}}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!props.session.token && (
          <>
            <span style={{ fontSize: 14 }}> Environment: </span> <br />
            <MenuItem onClick={() => { handleSetEnv('local') }}>
              {currentEnv === 'local'
                ?
                <CheckBox style={{ height: 24, width: 24, color: 'green' }} />
                :
                <CheckBoxOutlineBlankSharp style={{ height: 24, width: 24 }} />
              }
              <div className='realm--header-menu-item'>Local </div>
            </MenuItem>
            <MenuItem onClick={() => { handleSetEnv('dev') }}>
              {currentEnv === 'dev'
                ?
                <CheckBox style={{ height: 24, width: 24, color: 'green' }} />
                :
                <CheckBoxOutlineBlankSharp style={{ height: 24, width: 24 }} />
              }
              <div className='realm--header-menu-item'>Dev </div>
            </MenuItem>
            <MenuItem onClick={() => { handleSetEnv('staging') }}>
              {currentEnv === 'staging'
                ?
                <CheckBox style={{ height: 24, width: 24, color: 'green' }} />
                :
                <CheckBoxOutlineBlankSharp style={{ height: 24, width: 24 }} />
              }
              <div className='realm--header-menu-item'>Staging </div>
            </MenuItem>
            <MenuItem onClick={() => { handleSetEnv('prod') }}>
              {currentEnv === 'prod'
                ?
                <CheckBox style={{ height: 24, width: 24, color: 'green' }} />
                :
                <CheckBoxOutlineBlankSharp style={{ height: 24, width: 24 }} />
              }
              <div className='realm--header-menu-item'>Prod </div>
            </MenuItem>
            <Divider style={{ margin: 8 }} />
          </>
        )}


        {!props.session.token
          ?
          <MenuItem onClick={() => { window.location.href = "/"; }}>
            Sign In
          </MenuItem>
          :
          <MenuItem onClick={() => { window.location.href = "/logout"; }}>
            <Logout style={{ height: 24, width: 24 }} /> <div className='realm--header-menu-item'>Sign Out</div>
          </MenuItem>
        }
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;