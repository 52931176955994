const styles = {
  root: {
    display: 'inline-block',
    flexWrap: 'wrap',
    marginLeft: 20,
  },
  'control-label': {
    color: 'red'
  },
  buttonBlock: {
    width: '100%'
  },
  page: {
    width: '100%',
    height: '100vh',
    paddingTop: 100,
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat !important',
    background: `url('https://wp-realm-marketing.s3.amazonaws.com/images/ClientsBanner2.jpg') center center`
  },
  loginPanel: {
    margin: 'auto',
    maxWidth: 320,
    background: '#8488b5',
    borderRadius: 4,
    padding: 24
  },
  loadingContainer: {
    position: 'absolute',
    left: '50%',
    width: 120,
    marginLeft: -60
  },
  appBar: {
    position: 'relative',
    height: 60,
    padding: '20px 30px',
    fontSize: 20,
    background: '#474a6a'
  }
};

export default styles;
