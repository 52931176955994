import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import localstring from '../services/Localization';

const styles = () => ({
  topButton: {
    marginBottom: 8,
    padding: 0,
    width: 100,
    marginLeft: 8,
    fontSize: 11,
    letterSpacing: 0.5,
    paddingTop: 4,
    color: 'white',
    fontWeight: 300,
    borderRadius: 0,
    textDecoration: 'none',
    border: '2px white solid',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      textDecoration: 'none'
    },
  },
  topButtonLarge: {
    width: 200
  }
});

class QuotePanel extends Component {
  handleClick = () => {
    this.props.history.push('/about');
  }

  render() {
    return (
      <div className="realm--quote-panel">
        <div className="realm--quote-panel-item">
          { /*
          <div className="quote-panel--item-image">
            <img src={profilePic} alt="profile pic" />
          </div>
        */ }
          <div className="realm--quote-panel-item-content">
            <i className="fa fa-quote-left realm--quote-panel-quoteSize"></i>
            {localstring.page_home_032}
            <i className="fa fa-quote-right realm--quote-panel-quoteSize"></i>
            <p> <em>{localstring.page_home_033}</em></p>
          </div>
        </div>
        <div className="realm--quote-panel-item">
          { /*
          <div className="quote-panel--item-image">
            <img src={profilePic} alt="profile pic" />
          </div>
        */ }
          <div className="realm--quote-panel-item-content">
            <i className="fa fa-quote-left realm--quote-panel-quoteSize"></i>
            {localstring.page_home_034}
            <i className="fa fa-quote-right realm--quote-panel-quoteSize"></i>
            <p> <em>{localstring.page_home_035}</em></p>
          </div>
        </div>
        <div className="realm--quote-panel-item">
          { /*
          <div className="quote-panel--item-image">
            <img src={profilePic} alt="profile pic" />
          </div>
          */ }
          <div className="realm--quote-panel-item-content">
            <i className="fa fa-quote-left realm--quote-panel-quoteSize"></i>
            {localstring.page_home_036}
            <i className="fa fa-quote-right realm--quote-panel-quoteSize"></i>
            <p> <em>{localstring.page_home_037}</em></p>
          </div>
        </div>
        <Button
          onClick={this.handleClick}
          className={classNames(this.props.classes.topButton, this.props.classes.topButtonLarge)}
        >
          {localstring.page_button_learn_more}
        </Button>
      </div>
    );
  }
}

QuotePanel.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object
};

export default withStyles(styles)(QuotePanel);
