import axios from 'axios';
import * as storage from '../persistence/storage';

const token = storage.get('token') || '';

const api = axios.create({
//  baseURL: API_ROOT_LOCAL,
  timeout: 40000
});

if (token) {
  api.defaults.headers.common['X-Token'] = token;
}

api.setToken = (token) => {
  if (token) {
    api.defaults.headers.common['X-Token'] = token; 
  }
}

export default api;
