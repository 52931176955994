import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';


import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import { API_ROOT } from '../../constants';
import getParameterByName from '../../utils/get-parameter-by-name';

class Company extends Component {
  state = {
    content: {},
    isLoading: false
  };

  componentDidMount() {
    this.loadContent();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.application.locale !== this.props.application.locale ||
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.loadContent();
    }
  }

  loadContent = async () => {
    const page = getParameterByName('page');
    const { locale } = this.props.application;
    this.setState({ isLoading: true });
    const response = await axios.get(`${API_ROOT}/cms/content`, {
      params: {
        site: 'marketing',
        section: 'company',
        content: page,
        language: locale
      }
    });
    const content = response.data.data.data[0];
    if (content) {
      this.setState(
        {
          content,
          isLoading: false
        },
        () => {
          window.scrollTo(0, 0);
        }
      );
    }
  };

  createMarkup = () => ({ __html: this.state.content.content.rendered });

  createTitle = () => ({ __html: this.state.content.title.rendered });

  render() {
    const { content, isLoading } = this.state;
    const title = this.createTitle();
    return (
      <div className="realm--page-with-title">
        <PageTitle title={title} />
        <div className="realm--page-for-copy">
          {isLoading  ? (
            <LoadingIndicator />
          ) : (
            <div dangerouslySetInnerHTML={this.createMarkup()} />
          )}
        </div>
      </div>
    );
  }
}

Company.propTypes = {
  application: PropTypes.object,
  location: PropTypes.object
};

export default Company;
