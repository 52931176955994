/* eslint-disable arrow-parens */
import React from 'react';
// import PropTypes from 'prop-types';

import localstring from '../../services/Localization';

const Maintenance = () => (
  <div className="realmMaintenance">
    <h1>{localstring.page_maintenance_001}</h1>
    <p>{localstring.page_maintenance_002}</p>
    <h3 className="realmMaintenance--date">
      September 1, 2018
      {/* {props.date} */}
    </h3>
  </div>
);

Maintenance.propTypes = {
  // date: PropTypes.string
};

export default Maintenance;
