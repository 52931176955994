/* eslint-disable no-nested-ternary */
import './scss/index.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from './actions';

import App from './routes/App';
// import Login from './routes/Login/Login';
import UnsupportedBrowser from './routes/UnsupportedBrowser';

export class Application extends Component {
  state = {
    environment: 'local',
   };
  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const { isMenuActive } = this.props.application;
    if (isMenuActive) {
      this.props.actions.toggleMenu(false);
    }
  };

  // handleLogout = () => {
  //   window.location.replace('/logout');
  // };

  render() {
    const { application, session, actions } = this.props;
    const browserVersion = Number.parseInt(application.browserVersion, 10);
    // actions.authenticate({email:'test', password: 'test'});
    if (!application.isMobile && application.isIE && browserVersion < 11) {
      return <UnsupportedBrowser />;
    }
    // if (!session.isAuthenticated) {
    //   return (
    //     <Login actions={this.props.actions} session={this.props.session} />
    //   );
    // }
    return (
            <App application={application} actions={actions} session={session} location={this.props.location} />
    );
  }
}

Application.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
};


export default connect(
  (state) => ({
    application: state.application,
    session: state.session,
    router: state.router,
  }),
  (dispatch) => ({ actions: bindActionCreators(actions, dispatch) })
)(Application);

