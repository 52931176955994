import React from 'react';
import { Link } from 'react-router-dom';
import localstring from '../../services/Localization';

const PageNotFound = () => (
  <div className="page404">
    {localstring.page_404_header}
    <br />
    <div className="message404">
      {localstring.page_404_message}
    </div>
    <Link to="/" className="button404">
      {localstring.page_404_cta}
    </Link>
  </div>
);

export default PageNotFound;
