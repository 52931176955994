import * as storage from './storage';
import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_PENDING,
  SET_API_ENV,
  AUTHENTICATE_REJECTED,
} from '../constants';

import api from '../api';
import getRoles from '../utils/get-roles';

export default function persistenceHandler(next) {
  return (reducer, initialState) => {
    const store = next(reducer, initialState);
    return {
      ...store,
      dispatch(action) {
        store.dispatch(action);
        if (action) {
          if (
            action.type === '@@router/LOCATION_CHANGE' &&
            !action.payload.isFirstRendering
          ) {
            // storage.put('lastUpdated', Date.now());
          } else if (action.type === AUTHENTICATE_PENDING) {
            debugger;
            storage.put('lastUpdated', Date.now());
          } else if (action.type === AUTHENTICATE_REJECTED) {
            debugger;
            console.log(`AUTH REJECTED: ${action.payload}`)
          } else if (action.type === AUTHENTICATE_SUCCESS) {
            storage.put('token', action.payload.token);
            api.setToken(action.payload.token);
            debugger;
            storage.put('me', JSON.stringify(action.payload.user));

            storage.put('isAdmin', getRoles(action.payload, 'admin'));
            storage.put('isSuperAdmin', getRoles(action.payload, 'superadmin'));
            storage.put('isDeveloper', getRoles(action.payload, 'dev'));
            storage.put('isAgent', getRoles(action.payload, 'agent'));

            storage.put('isConcierge', getRoles(action.payload, 'concierge'));
            storage.put('isDemo', getRoles(action.payload, 'demo'));
            storage.put('isRealmAdmin', getRoles(action.payload, 'realmAdmin'));
            storage.put(
              'isRealmExecutive',
              getRoles(action.payload, 'realmExecutive')
            );
            storage.put('lastUpdated', Date.now());
          } else if (action.type === SET_API_ENV) {
            storage.put('currentAPIEnv', action.payload.env);
            storage.put('api_root', action.payload.newBaseUrl);
          }
        }
        return action;
      }
    };
  }
}
