import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Grid } from '@material-ui/core';

import { API_ROOT } from '../../constants';
import getParameterByName from '../../utils/get-parameter-by-name';
import BlogList from '../../components/BlogList';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';

class BlogPost extends Component {
  state = {
    content: {},
    isLoading: true
  };

  _isMounted = false;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadContent();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.application.locale !== this.props.application.locale ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.loadContent();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadContent = async () => {
    const slug = getParameterByName('name');
    const { locale } = this.props.application;
    this.setState({ isLoading: true });
    const response = await axios.get(`${API_ROOT}/cms/full-post`, {
      params: {
        slug,
        language: locale
      }
    });
    const content = response.data.data.data[0];
    if (content && this._isMounted) {
      this.setState(
        {
          content,
          isLoading: false
        },
        () => {
          window.scrollTo(0, 0);
        }
      );
    }
  };

  createMarkup = () => ({ __html: this.state.content.content.rendered });

  createTitle = () => {
    let name = this.state.content.title.rendered;
    const firstBrace = name.indexOf('{') + 1;
    const lastBrace = name.lastIndexOf('}');
    name = name.substring(firstBrace, lastBrace);
    return name;
  };

  render() {
    const { content, isLoading } = this.state;
    const { application } = this.props;

    return (
      <div className="realm--page-with-title">
        <PageTitle title="Return to Blog" backTo="/blog" />
        <div className="realm--page-for-copy">
          {isLoading  ? (
            <LoadingIndicator />
          ) : (
            <Grid container spacing={32}>
              <Grid item sm={8} lg={9}>
                <div className="realm--post-title">{this.createTitle()}</div>
                <div
                  className="realm--full-post"
                  dangerouslySetInnerHTML={this.createMarkup()}
                />
              </Grid>
              <Grid item sm={4} lg={3}>
                <BlogList
                  categories={[24]}
                  count={5}
                  language={application.locale}
                  title="Featured"
                  view="list"
                />
                <BlogList
                  categories={[26]}
                  count={5}
                  language={application.locale}
                  title="Real Estate"
                  view="list"
                />
                <BlogList
                  categories={[27]}
                  count={5}
                  language={application.locale}
                  title="Life Style"
                  view="list"
                />
                <BlogList
                  categories={[17]}
                  count={5}
                  language={application.locale}
                  title="News"
                  view="list"
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

BlogPost.propTypes = {
  application: PropTypes.object,
  location: PropTypes.object
};

export default BlogPost;
