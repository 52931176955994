// import 'babel-polyfill';
import React from 'react';
// import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
 import { Provider } from 'react-redux';

import store from './store';
import Application from './Application';

const domNode = document.getElementById('app');


// react-dom 18 version -----------------------------------
// import * as ReactDOM from 'react-dom/client';
// const root = ReactDOM.createRoot(domNode);
// root.render(
//   <Provider store={store} >
//     <Router onUpdate={() => window.scrollTo(0, 0)}>
//       <Application />p
//     </Router>
//   </Provider>
// );

// react-dom 17 version -----------------------------------
import ReactDOM from 'react-dom';
const Realm = () => (
  <Provider store={store}>
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      <Application />
    </Router>
  </Provider>
);

ReactDOM.render(<Realm />, domNode);
