import hasStorage from '../utils/has-storage';

export function put(key, value) {
  if (!value) { return null; }
  const storageObj = typeof value !== 'string' ? JSON.stringify(value) : value;
  if (hasStorage) {
    window.sessionStorage.setItem(key, storageObj);
  }
  return null;
}

export function get(key) {
  return hasStorage ? window.sessionStorage.getItem(key) : '';
}

export function remove(key) {
  return hasStorage && window.sessionStorage.removeItem(key);
}

export function clear() {
  if (hasStorage) {
    window.sessionStorage.clear();
  }
}
