import * as React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckBox, CheckBoxOutlineBlankSharp, EmailOutlined, Assessment, People } from '@material-ui/icons';

import Logout from '@material-ui/icons/ExitToApp';
import api from '../api';
import { API_URLS, ENV_COLORS } from '../constants';
import { Settings as Settings } from '@material-ui/icons'

const ToolsMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentEnv, setCurrentEnv] = React.useState(props.application.currentAPIEnvironment);
  const myInitials = 'RA';
  const open = Boolean(anchorEl);

  const toolsList = [
    {name: 'Weekly Reporting', url: '/weekly-reporting', icon: Assessment},
    {name: 'Subscription Overview', url: '/subscriptions', icon: People },
    {name: 'Test Emails', url: '/test-emails', icon: EmailOutlined},
    {name: 'Email Logs', url: '/email-logs', icon: EmailOutlined},
  ]

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    api.get(`${props.application.apiRoot}/info/system-settings/`)
  },
    [props.application.apiRoot])

  const launchTool = (tool, url) => {
    props.history(url);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Administrative Tools">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Settings style={{height: 54, width: 54, fontSize: 16, padding: 6, marginBottom: 8, color: 'rebeccapurple' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {padding: 12},
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            pl: 12,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        style={{padding: 12}}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <span style={{fontSize: 14}}> TOOLS </span> <br />
        {toolsList.map((tool) => (
          <>
            <MenuItem onClick={() => { launchTool(tool.name, tool.url) }}>
              <tool.icon style={{ height: 24, width: 24 }} />
              <div className='realm--header-menu-item'>{tool.name}</div>
            </MenuItem>

            {/* <Divider style={{ margin: 8 }} /> */}
            </>
        ))}
        <MenuItem>
          <div>
            <a href="https://dev.etl.realm-global.com/logs">View Jobs runtime graphs</a>
          </div>
        </MenuItem>
       
      </Menu>
    </React.Fragment>
  );
}

export default ToolsMenu;