// import createHistory from 'history/createBrowserHistory';
// import { useNavigate } from 'react-router-dom';
import configureStore from './configurestore';

const initialState = {};

// const history = createHistory();
// const history = useNavigate();
// const store = configureStore(initialState, history);
const store = configureStore(initialState);
export default store;
