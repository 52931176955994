import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle';
import {Button, Table, TableContainer, TableHead, TableRow, Paper, TableCell, TableBody} from '@material-ui/core';
import moment from 'moment';
import api from '../../api';
import axios from 'axios';
import LoadingIndicator from '../../components/LoadingIndicator';

const Subscriptions = (props) => {
  const [subData, setSubData] = useState({count: 0, list: []});
  const [isLoading, setIsLoading] = useState(true);
  const [months, setMonths] = useState([]);
  const today = moment();

  const amounts = {
    'REALM Brokerage Annual': 15000,
    'REALM Exclusive Member Team (Annual)': 7500,
    'REALM Exclusive Member Team (Monthly)': 750,
    'REALM Team': 500,
    'REALM Team Annual': 5000,
    'Pacaso Enterprise Annual' : 15000,
    'REALM Exclusive Team Two-Year (Monthly)': 750,
    'REALM Founding Member Team (Monthly)': 500,
    'REALM Founding Member Team (Annual)': 5000,
    'REALM Exclusive Team Two-Year (Biennial)': 11000,
    'REALM Exclusive Team Two-Year (Monthly)': 600,
    'REALM Exclusive Member Two-Year (Biennial)': 11000,
    'REALM Exclusive Member Two-Year (Monthly)': 600,
    'REALM Team Monthly': 500,
    'REALM Exclusive Member Agent (Annual)': 7500,
    'REALM Exclusive Member Agent (Monthly)': 750,
    'REALM Agent Annual': 5000,
    'REALM Developer Annual': 15000,
    'REALM Founding Member Agent (Annual)': 5000,
    'REALM Founding Member Agent (Monthly)': 500,
    'REALM Agent Monthly': 500,
    'REALM Agent': 5000,
  }

  const getData = async() => {
    const response = await api.get(`${props.application.apiRoot}/subscriptions/list/current`);
    const sdata = response.data.data;
    setSubData(sdata);
    const month = moment().month();
    const ms = [];
    for( let m=0; m<24; m++) {
      ms.push(moment().add(m, 'months').format(`MMM 'YY`))
    }
    setMonths(ms)
  };

  useEffect(() => {
    // load the data
    setIsLoading(true);
    getData();
  },
    [])

  useEffect(() => {
    if (months.length > 0) {
      setIsLoading(false);
    }
  },[months])

  const render24Months = (row) => {
    const period = row.subName.includes('Month') || row.subName === 'REALM Team' ? 'Monthly' : row.subName.includes('Biennial') ? 'Biennial' : 'Annual';
    const length = row.subName.includes('Two-Year') ? 2 : 1;
    const amount = amounts[row.subName];
    const monthsLeft = moment(row.subEnd).diff(today, 'months', true);
    return (
      <>
      {months.map((m, index) => (
        <>
        {monthsLeft > (index)
        ?
        <>
        {period !== 'Monthly' 
        ?
        <TableCell style={{padding: 0}}>
          <div style={{width: '100%', background: 'green', textAlign: 'center'}}>-&nbsp;-</div>
        </TableCell>
        :
        <TableCell  style={{padding: 0, color: '#11a511'}}>
          ${amount}
        </TableCell>
  }
        </>
        :
        <TableCell></TableCell>
}
        </>
      ))}
      </>
    )
  }

  const renderTableBody = () => (
    <TableBody style={{height: 400, overflow: 'hidden'}}>
      {subData.list.map((row, index) => (
        <TableRow key={`sub-row-${index}`}>
          <TableCell>{index+1}</TableCell>
          <TableCell scope="row">
            {row.brokerage ? row.brokerage : row.team ? row.team : row.memberName}
            {row.numberOfSubs > 1 && (
              <div>({row.numberOfSubs} members)</div>
            )}
          </TableCell>
          <TableCell>{row.subType}</TableCell>
          <TableCell>{row.subName}</TableCell>
          <TableCell>${amounts[row.subName]}</TableCell>
          <TableCell>
            {row.subName.includes('Month')|| row.subName === 'REALM Team' ? 'Monthly' : row.subName.includes('Biennial') ? 'Biennial' : 'Annual'}           
            </TableCell>
          <TableCell>{moment(row.subEnd).format(`M/D/YY`)}</TableCell>
          <TableCell style={{padding: 0, textAlign: 'center'}}>
            {row.subPaid}
            {row.stripeCustomer && (
              <div>{row.stripeCustomer}</div>
            )}
            </TableCell>
          {render24Months(row)}
          {/* <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
      ))}
    </TableBody>
  )

  return (
    <div className="realm--page-with-title" style={{minHeight: '100vh'}}>
      <PageTitle title='Subscriptions'/>
      {isLoading
      ?
      <LoadingIndicator />
      :
      <div style={{margin: 24, fontSize: 18, width: 2600}}>
        <div style={{marginBottom: 24}}>
          Total Subscriptions: {subData.list.length} | Total Members: {subData.count}
        </div>
        <TableContainer component={Paper} style={{maxHeight: 'calc(100vh - 240px)'}}>
        <Table size='small'  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{width: 72}}>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell style={{width: 106}}>Type</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell style={{width: 95}}>Amount</TableCell>
              <TableCell style={{width: 95}}>Freq.</TableCell>
              <TableCell style={{width: 106}}>End Date</TableCell>
              <TableCell style={{width: 172, textAlign: 'center'}}>Pay Method</TableCell>
              {months.map(m => (
                <TableCell>{m}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {renderTableBody()}
        </Table>
        </TableContainer>
      </div>}
    </div>
  )
}

export default Subscriptions;