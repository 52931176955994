import axios from 'axios';
import * as constants from '../constants';
import store from '../store';
import * as storage from '../persistence/storage';

export const authenticate = (authData, rootAPI) => (dispatch) => {
  dispatch({ type: constants.AUTHENTICATE_PENDING, payload: {} });
  axios
    .post(`${rootAPI}/user/authenticate/cas`, {
      ...authData
    })
    .then((response) => {
      debugger;
      dispatch({
        type: constants.AUTHENTICATE_SUCCESS,
        payload: response.data
      });
    })
    .catch((err) => {
      debugger;
      dispatch({ type: constants.AUTHENTICATE_REJECTED, payload: err });
    });
};

export const retryAuthentication = () => (dispatch) => {
  dispatch({ type: constants.AUTHENTICATE_RETRY, payload: {} });
};
