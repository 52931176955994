import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { Button, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import axios from 'axios';

import LoadingIndicator from '../../components/LoadingIndicator';
import localstring from '../../services/Localization';

import ContactUs from '../../components/ContactUs';
import QuotePanel from '../../components/QuotePanel';

import topImage from '../../images/photos/upsplash.jpg';
import panel01 from '../../images/photos/staircase.jpg';
import panel02 from '../../images/photos/landscape-road.jpg';
import panel03 from '../../images/photos/golden-gate.jpg';
import panel04 from '../../images/photos/bridal-car.jpg';

import collage01 from '../../images/photos/collage1.jpg';
import collage02 from '../../images/photos/collage2.jpg';
import collage03 from '../../images/photos/collage3.jpg';
import collage04 from '../../images/photos/collage4.jpg';
import collage05 from '../../images/photos/collage5.jpg';
import collage06 from '../../images/photos/collage6.jpg';
import collage07 from '../../images/photos/collage7.jpg';
import collage08 from '../../images/photos/collage8.jpg';
import collage09 from '../../images/photos/collage9.jpg';
import collage10 from '../../images/photos/collage10.jpg';

/* eslint-disable arrow-parens */
const styles = () => ({
  cssButton: {
    margin: 8,
    padding: 0,
    width: 120,
    color: '#eee',
    fontSize: 12,
    fontWeight: 300,
    letterSpacing: 0.5,
    paddingTop: 4,
    backgroundColor: '#9999b8',
    '&:hover': {
      backgroundColor: '#bbc',
      textDecoration: 'none'
    }
  },
  topButton: {
    marginBottom: 8,
    padding: 0,
    width: 100,
    marginLeft: 8,
    fontSize: 11,
    letterSpacing: 0.5,
    paddingTop: 4,
    color: 'white',
    fontWeight: 300,
    borderRadius: 0,
    textDecoration: 'none',
    border: '2px white solid',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      textDecoration: 'none'
    }
  },
  topButtonLarge: {
    width: 200
  },
  blackButton: {
    marginTop: 20,
    marginLeft: 0,
    fontSize: 14,
    letterSpacing: '0.1rem',
    width: 128,
    paddingBottom: 2,
    webkitFontSmoothing: 'none',
    color: 'black',
    textTransform: 'uppercase',
    border: '2px #888 solid',
    '&:hover': {
      backgroundColor: '#eee',
      color: 'black',
      borderColor: 'black',
      textDecoration: 'none'
    }
  },
  topimage: {
    background: `url(${topImage}) top center`,
    boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.2)',
    backgroundSize: 'cover',
    width: '100%',
    height: '90vh',
    minHeight: '580px'
  },
  panel01Image: {
    background: `url(${panel01}) center left`
  },
  panel02Image: {
    background: `url(${panel02}) center right`
  },
  panel03Image: {
    background: `url(${panel03}) center left`
  },
  panel04Image: {
    background: `url(${panel04}) center right`
  },
  collage01Image: {
    background: `url(${collage01}) center bottom`
  },
  collage02Image: {
    background: `url(${collage02}) center center`
  },
  collage03Image: {
    background: `url(${collage03}) center center`
  },
  collage04Image: {
    background: `url(${collage04}) center center`
  },
  collage05Image: {
    background: `url(${collage05}) center center`
  },
  collage06Image: {
    background: `url(${collage06}) center center`
  },
  collage07Image: {
    background: `url(${collage07}) center right`
  },
  collage08Image: {
    background: `url(${collage08}) center center`
  },
  collage09Image: {
    background: `url(${collage09}) center bottom`
  },
  collage10Image: {
    background: `url(${collage10}) center center`
  }
});

class Home extends Component {
  state = {
    // content: '',
    isLoading: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const p = this.props;
  }

  componentDidUpdate(prevProps) {
    const { locale: language } = this.props.application;
    if (prevProps.application.locale !== language) {
      if (localstring._props[language].page_home_000) {
        // then it already has been loaded
      } else {
        this.getHomeData();
      }
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  getHomeData = async () => {
    this.setState({ isLoading: true });
    const slug = `slug=marketing-home-${this.props.application.locale}`;
    const response = await axios.get(
      `https://www.curveten.com/clientportal/wp-json/wp/v2/posts?${slug}`
    );
    const content = response.data[0];
    const strippedContent = content.content.rendered.replace(
      new RegExp('<p>{', 'g'),
      ''
    );
    const contentArray = strippedContent.split('</p>');
    for (let c = 0; c < contentArray.length; c++) {
      const nextContent = contentArray[c].split('}:');
      let nextName = nextContent[0];
      if (nextContent[1]) {
        const index0 = nextName.indexOf('0');
        if (index0 > 0) {
          nextName = nextName.substring(index0);
        }
        nextName = `page_home_${nextName}`;
        // localstring._props[this.state.language][nextName] = nextContent[1];
        // localstring[nextName] = nextContent[1];
      }
    }
    this.setState({
      // content: response.data[0],
      isLoading: false
    });
  };

  scrollToPanel1 = () => {
    document.getElementById('Anchor1').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  };

  scrollToPanelContact = () => {
    document.getElementById('Contact').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  };

  scrollToPanelHome= () => {
    document.getElementById('HomeTop').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  };

  render() {
    const { actions, classes, session } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="realm--wrapper" id="HomeTop">
        {isLoading && (
          <div className="realm--loader">
            <LoadingIndicator />
          </div>
        )}
        <div className="realm--container">
          <div className={classes.topimage}>
            <div className="realm--marquee-text">
              <div className="realm--top-copy-area">
                <span>
                  <h2>{localstring.page_home_000}</h2>
                  <h3>{localstring.page_home_001}</h3>
                </span>
                {/*
                <div className="realm--actions">
                  <Button
                    className={classes.topButton}
                    onClick={this.scrollToPanel1}
                  >
                    <span>{localstring.general_agents}</span>
                  </Button>
                  <Link to="/for-Clients">
                    <Button className={classes.topButton}>
                      {localstring.general_clients}
                    </Button>
                  </Link>
                </div>
                */}
              </div>
            </div>
          </div>
          <div id="Anchor1" className="realm--photo-panel">
            <div
              className={classNames(
                'text-panel-left panel-image',
                classes.panel01Image
              )}
            >
              <div className="text-panel-left--content">
                <div className="text-panel-left--realm">
                  {localstring.page_home_002}
                </div>
                <div className="text-panel-left--big-word">
                  {localstring.page_home_003}
                </div>
              </div>
            </div>
            <div className="text-panel-right">
              <div className="text-panel-right--content">
                <strong>{localstring.page_home_004}</strong>
                <br />
                <br />
                {localstring.page_home_005}
                <br />
                <br />
                {localstring.page_home_006}
                <br />
                <br />
                <Button
                  className={classNames(classes.topButton, classes.blackButton)}
                  onClick={this.scrollToPanelContact}
                >
                  {localstring.page_button_join}
                </Button>
              </div>
            </div>
          </div>
          <div className="realm--photo-panel reverse">
            <div
              className={classNames(
                'text-panel-left panel-image',
                classes.panel02Image
              )}
            >
              <div className="text-panel-left--content">
                <div className="text-panel-left--realm">
                  {localstring.page_home_007}
                </div>
                <div className="text-panel-left--big-word">
                  {localstring.page_home_008}
                </div>
              </div>
            </div>
            <div className="text-panel-right">
              <div className="text-panel-right--content">
                <strong>{localstring.page_home_009}</strong>{' '}
                {localstring.page_home_010}
                <br />
                <br />
                {localstring.page_home_011}
                <br />
                <br />
                <Button
                  className={classNames(classes.topButton, classes.blackButton)}
                  onClick={this.scrollToPanelContact}
                >
                  {localstring.page_button_join}
                </Button>
              </div>
            </div>
          </div>
          <div id="Anchor2" className="realm--photo-panel">
            <div
              className={classNames(
                'text-panel-left panel-image',
                classes.panel03Image
              )}
            >
              <div className="text-panel-left--content">
                <div className="text-panel-left--realm">
                  {localstring.page_home_012}
                </div>
                <div className="text-panel-left--big-word">
                  {localstring.page_home_013}
                </div>
              </div>
            </div>
            <div className="text-panel-right">
              <div className="text-panel-right--content">
                <strong>{localstring.page_home_014}</strong>
                <br />
                <br />
                {localstring.page_home_015}
                <br />
                <br />
                <Button
                  className={classNames(classes.topButton, classes.blackButton)}
                  onClick={this.scrollToPanelContact}
                >
                  {localstring.page_button_join}
                </Button>
              </div>
            </div>
          </div>
          <div className="realm--photo-panel reverse">
            <div
              className={classNames(
                'text-panel-left panel-image',
                classes.panel04Image
              )}
            >
              <div className="text-panel-left--content">
                <div className="text-panel-left--realm">
                  {localstring.page_home_016}
                </div>
                <div className="text-panel-left--big-word">
                  {localstring.page_home_017}
                </div>
              </div>
            </div>
            <div className="text-panel-right">
              <div className="text-panel-right--content">
                <strong>{localstring.page_home_018}</strong>
                <br />
                <br />
                {localstring.page_home_019}
                <br />
                <br />
                <Button
                  className={classNames(classes.topButton, classes.blackButton)}
                  onClick={this.scrollToPanelContact}
                >
                  {localstring.page_button_join}
                </Button>
              </div>
            </div>
          </div>
          <div className="realm-collage">
            <div className="realm-collage--row">
              <div className="realm-collage--item collage--item-large">
                <div
                  className={classNames(
                    'text-panel-left collage-panel--base collage-panel--square-height',
                    classes.collage01Image
                  )}
                >
                  <div className="text-panel-left--content">
                    <div className="text-panel-left--realm">
                      {localstring.page_home_020}
                    </div>
                    <div className="text-panel-left--big-word">
                      {localstring.page_home_021}
                    </div>
                  </div>
                </div>
              </div>
              <div className="realm-collage--item collage--item-split">
                <div
                  className={classNames(
                    'collage--item-image collage-panel--base collage-panel--small hide-mobile',
                    classes.collage02Image
                  )}
                />
                <div className="realm-collage--item-blurb">
                  <div>{localstring.page_home_022}</div>
                </div>
              </div>
            </div>
            <div className="realm-collage--row hide-mobile">
              <div className="realm-collage--item">
                <div
                  className={classNames(
                    'collage-panel--base collage-panel--square',
                    classes.collage03Image
                  )}
                />
              </div>
              <div className="realm-collage--item">
                <div
                  className={classNames(
                    'collage-panel--base collage-panel--square',
                    classes.collage04Image
                  )}
                />
              </div>
              <div className="realm-collage--item">
                <div
                  className={classNames(
                    'collage-panel--base collage-panel--square',
                    classes.collage05Image
                  )}
                />
              </div>
              <div className="realm-collage--item">
                <div
                  className={classNames(
                    'collage-panel--base collage-panel--square',
                    classes.collage06Image
                  )}
                />
              </div>
            </div>
            <div className="realm-collage--row collage--row-short reverse">
              <div className="realm-collage--item collage--item-large">
                <div
                  className={classNames(
                    'text-panel-left collage-panel--base, collage-panel--square-height',
                    classes.collage08Image
                  )}
                >
                  <div className="text-panel-left--content">
                    <div className="text-panel-left--realm">
                      {localstring.page_home_024}
                    </div>
                    <div className="text-panel-left--big-word">
                      {localstring.page_home_025}
                    </div>
                  </div>
                </div>
              </div>
              <div className="realm-collage--item collage--item-split">
                <div className="realm-collage--item-blurb">
                  <div>{localstring.page_home_026}</div>
                </div>
                <div
                  className={classNames(
                    'collage--item-image hide-mobile collage-panel--base collage-panel--small',
                    classes.collage07Image
                  )}
                />
              </div>
            </div>
            <div className="realm-collage--row collage--row-long">
              <div className="realm-collage--item">
                <div
                  className={classNames(
                    'text-panel-left bottom collage-panel--base collage-panel--vertical',
                    classes.collage09Image
                  )}
                >
                  <div className="text-panel-left--content">
                    <div className="text-panel-left--realm">
                      {localstring.page_home_027}
                    </div>
                    <div className="text-panel-left--big-word">
                      {localstring.page_home_028}
                    </div>
                  </div>
                </div>
              </div>
              <div className="realm-collage--item">
                <div
                  className={classNames(
                    'text-panel-left hide-mobile collage-panel--base collage-panel--square-height',
                    classes.collage10Image
                  )}
                />
                <div className="realm-collage--item-blurb short">
                  <div>{localstring.page_home_022}</div>
                </div>
              </div>
            </div>
          </div>
          <QuotePanel history={this.props.history} />
          <ContactUs />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
