import React, { Component } from 'react';
import LoadingIndicator from '../../components/LoadingIndicator';
import localstring from '../../services/Localization';

import jhlogo from '../../images/img/jackson-hole-logo.png';

class About extends Component {
  state = {
    isLoading: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }
    return (
      <div className="realm--page-with-title">
        <div className="realm--about">
          <h1>
            <span className="realm--about-label">
              {localstring.page_about_001}
            </span>
            {localstring.page_about_002}
          </h1>
          <p className="realm--about-leadGraph">{localstring.page_about_005}</p>
          <div className="realm--about-list">
            <div className="realm--about-list-item">
              <h3>{localstring.page_about_006}</h3>
              <p>{localstring.page_about_007}</p>
            </div>
            <div className="realm--about-list-item">
              <h3>{localstring.page_about_008}</h3>
              <p>{localstring.page_about_009}</p>
            </div>
            <div className="realm--about-list-item">
              <h3>{localstring.page_about_010}</h3>
              <p>{localstring.page_about_011}</p>
            </div>
          </div>
        </div>
        <div className="realm--about realm--about-partners">
          <h2>
            <span className="realm--about-label">
              {localstring.page_about_003}
            </span>
            {localstring.page_about_004}
          </h2>
          {/* <div className="realm--about-partner">
                <div className="realm--about-partner-image">
                  <img src={profilePic} alt="partner pacific union" />
                </div>
                <div className="realm--about-partner-content">
                  <h3>{localstring.page_about_012}</h3>
                  <p>{localstring.page_about_013}</p>
                </div>
              </div> */}
          <div className="realm--about-partner">
            <div className="realm--about-partner-image">
              <img src={jhlogo} alt="partner jackson hole" />
            </div>
            <div className="realm--about-partner-content">
              <h3>{localstring.page_about_014}</h3>
              <p>{localstring.page_about_015}</p>
            </div>
          </div>
          <div className="realm--about-partner">

            <div className="realm--about-partner-content">
              <h3>{localstring.page_about_016}</h3>
              <p>{localstring.page_about_017}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
