/**
 * Retrieve param in query string
 *
 * Returns query string parameter by entering param name
 *
 * @param {name} incoming parameter to be returned
 * @returns {string}
 */
export default function getParameterByName(name) {
  const location = window.location || '';
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
