import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Logout from '@material-ui/icons/ExitToApp';
import api from '../api';
import { API_URLS } from '../constants';
import EmailsToTest from '../dataInput/email-tests.json';
import { Grid } from '@material-ui/core';

const ToolEmails = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailTo, setEmailTo] = useState('realmbetauser+email-unit-testRecipient@gmail.com');
  const [emailBCC, setEmailBCC] = useState('realmbetauser+email-unit-testBCC@gmail.com');
  const [emailFrom, setEmailFrom] = useState('realmbetauser+email-unit-testSender@gmail.com');
  const [emailSubject, setEmailSubject] = useState('Test Email Subject (replaced by middleware)');
  const [emailListing, setEmailListing] = useState('12345 (replaced by middleware)');
  const [emailMessage, setEmailMessage] = useState('Placeholder message (replaced by middleware)');
  const [emailToAgentName, setEmailToAgentName] = useState('Recipient Name');
  const [emailFromAgentName, setEmailFromAgentName] = useState('Sender Name');
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    // reload as apiRoot has changed
    api.get(`${props.application.apiRoot}/info/system-settings/`)
  },
    [props.application.apiRoot])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendTestEmail = async(email) => {
    const parameters = {
      emailTo, emailBCC, emailFrom, emailToAgentName, emailFromAgentName, emailSubject, emailListing, emailMessage
    };
    const body = {email, parameters};

    const response = await api.post(`${props.application.apiRoot}/admin/send-test-email/`, body);
    console.log(response);
    alert(response.data.data.message);
  }

  const renderForm = () => {
    return(
      <div className='tools--email-form'>
        Email Parameters<br /><br />
        <Grid 
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item sm={4} >
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>To:</div>{emailTo}
            </div>
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>BCC:</div>{emailBCC}
            </div>
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>From:</div>{emailFrom}
            </div>
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>To Agent Name:</div>{emailToAgentName}
            </div>
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>From Agent Name</div>{emailFromAgentName}
            </div>
          </Grid>
          <Grid item sm={4} >
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>Subject:</div>{emailSubject}
            </div>
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>Listing: </div>{emailListing}
            </div>
            <div className='tools--email-form-line'>
              <div className='tools--email-form-label'>Message: </div>
              <div className='tools--email-form-body'>{emailMessage}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
      <div className='realm--page'>
        <div className='realm--page-title'>Tools: Email Testing</div>
        {renderForm()}
        Emails to Test:
        <Table>
          <TableBody>
        {EmailsToTest.map((email) => (
          <TableRow >
            <TableCell className='tools--email-cell tools--email-cell-100'># {email.number}{email.subNumber}</TableCell>
             <TableCell className='tools--email-cell tools--email-cell-350'>Event: {email.Event}</TableCell>
            <TableCell><Button variant='contained' color='primary' onClick={() => sendTestEmail(email)}>Send Test</Button></TableCell>
          </TableRow>
        ))}
        </TableBody>
        </Table>
      </div>
  );
}

export default ToolEmails;