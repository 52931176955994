import React from 'react';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import axios from 'axios';

import logoImage from '../images/logos/REALM_white_TM.png';

const styles = {
  root: {
    // display: 'flex',
    //  justifyContent: 'center',
    display: 'inline-block',
    flexWrap: 'wrap',
    marginLeft: 20,
    fontSize: 10,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {

  },
  select: {
    fontFamily: 'Roboto, sans-serif',
    palette: {
      textColor: 'white',
    }
  },
  appBar: {
    position: 'relative',
    height: 100,
    padding: 30,
    fontSize: 30,
    background: '#000',
    marginBottom: 48,
  },
  titleArea: {
    fontFamily: '"Cormorant","Georgia","Times New Roman",Times,serif',
    fontSize: 24,
    margin: 24,
    paddingTop: 48,
    width: 350,
  },
  section: {
    margin: '24px 48px 0px',

  },
  formFont: {
    fontSize: 14
  },
  flex: {
    flex: 1,
  },
  statusBlock: {
    borderBottom: '1px solid gray',
    padding: 24,
    maxWidth: 800,
  },

  formArea: {
    marginTop: 100,
    marginLeft: ' 33%',
    marginRight: ' 33%',
    fontSize: 18
  },
  signInButton: {
    marginTop: 24,
    marginBottom: 8,
    padding: 0,
    width: 150,
    marginLeft: 8,
    color: '#eee',
    fontSize: 14,
    fontWeight: 100,
    letterSpacing: 0.5,
    backgroundColor: '#9999b8',
    '&:hover': {
      backgroundColor: '#bbc',
    },
  },
  pageBackground: {
    minHeight: '85vh',
    backgroundColor: '#aaaab6',
  }

};


const checks = [
  {
    type: 'PRODUCTION',
    url: 'https://prod.realm-global.com',
    services: [
      {
        status: '',
        name: 'API Services',
        url: 'https://48dnz9g9w3.execute-api.us-east-1.amazonaws.com/prod'
      },
      {
        status: '',
        name: 'U-Services',
        url: 'https://prod.uservice.realm-global.com'
      },
      {
        status: '',
        name: 'RI Services',
        url: 'https://prod-ri.etl.realm-global.com/info'
      },
    ]
  },
  {
    type: 'STAGING',
    url: 'https://staging.realm-global.com',
    services: [
      {
        status: '',
        name: 'API Services',
        url: 'https://lkvhry3xr2.execute-api.us-east-1.amazonaws.com/stage'
      },
      {
        status: '',
        name: 'U-Services',
        url: 'https://stage.uservice.realm-global.com'
      },
      {
        status: '',
        name: 'RI Services',
        url: 'https://stage.etl.realm-global.com/info'
      },
    ]
  },
  {
    type: 'DEV',
    url: 'https://dev.realm-global.com',
    services: [
      {
        status: '',
        name: 'API Services',
        url: 'https://f9i8fscvgi.execute-api.us-east-1.amazonaws.com/dev/'
      },
      {
        status: '',
        name: 'U-Services',
        url: 'https://dev.uservice.realm-global.com/'
      },
      {
        status: '',
        name: 'RI Services',
        url: 'https://dev.etl.realm-global.com/info'
      },
    ]
  },

];

// ****************************************************
//
// COMPONENT STARTS HERE
//
// ****************************************************

class HealthDashboard extends React.Component {
  constructor() {
    super();
    const t1 = ' Super User';
    const t2 = 'Over$5B'

    // Create the root video element
    const video = document.createElement('video');
    this.state = {
      t1: `Realm${t1}`,
      t2: `Realm${t2}`,
      loading: true,
      touchColor: true,
      video,
      imageUrl: 'https://wp-realm-marketing.s3.amazonaws.com/images/RealmBlackBackground.png?t=',
      myWindow: null,
      
    };
  }

  async checkServices() {
    checks.forEach((environment) => {
      environment.services.forEach(async (service) => {
        try {
          await axios.get(service.url);
          service.status = 'good';
          service.lastCheck = new Date().toUTCString();
          this.setState({ loading: !this.state.loading });

        } catch (err) {
          const e = err;
          console.log(e);
          if ((e.response && e.response.data.error === 'Unauthorized user') 
            || (e.response && e.response.message === 'realmintelligence') )
          {
            service.status = 'good';
          } else {
            service.status = 'bad';
          }
          service.lastCheck = new Date().toUTCString();
          this.setState({ loading: !this.state.loading });
        }
      });
    });
    this.setState({ loading: !this.state.loading });
  }


  componentDidMount() {
    this.checkServices();
  }

  updateStatus = async () => {
    checks.forEach((type) => (
      type.services.forEach((service) => {
        service.status = '';
      })
    ));
    this.setState({ loading: !this.state.loading }, async () => {
      await this.checkServices();
    })
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.pageBackground}>

        {true && (
          <>
            <div className={classes.titleArea} style={{width:'80%'}}>
              REALM Health Dashboard
              <Button
                style={{ marginTop: 12, float: 'right' }}
                color="primary"
                variant="contained"
                onClick={this.updateStatus}
              >
                Refresh
              </Button>
            </div>
            {checks.map((section, s) => (
              <div className={classes.section} key={`section-div-${section.type}`}>
                <a href={section.url} key={`section-${section.type}`}>{section.type}</a>
                {section.services.map((site, r) => (
                  <div className={classes.statusBlock} key={`site-${section.type}-${site.name}`}>
                      <>
                        {site.status === '' && (
                          <i
                            className="fa fa-share fa-spin"
                            style={{ marginRight: 24 }}
                            key={`site-${section.type}--${site.name}`}
                          />
                        )}
                        {site.status === 'good' && (
                          <i
                            className="fa fa-circle"
                            style={{ marginRight: 24, color: 'green' }}
                            key={`site-${section.type}--${site.name}`}
                          />
                        )}
                        {site.status === 'bad' && (
                          <i
                            className="fa fa-circle"
                            style={{ marginRight: 24, color: 'red' }}
                            key={`site-${section.type}--${site.name}`}
                          />
                        )}
                      </>
                    {site.name} <a href={site.url} style={{ float: 'right' }} key={`visit-${section.type}--${site.name}`}> {site.lastCheck && (<span key={`span-${section.type}--${site.name}`}>Last Check: {site.lastCheck}</span>)}&nbsp;&nbsp;&nbsp; visit >></a>
                  </div>
                ))
                }
              </div>
            ))
            }
          </>
        )}
        <div style={{ marginBottom: 48 }}></div>
      </div>
    );
  }
}


export default withStyles(styles)(HealthDashboard);
