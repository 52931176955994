import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PageTitle = (props) => {
  const { addPlus, backTo, title } = props;
  const isTitleString = typeof title === 'string';
  return (
    <div className="realm--page-title-bar">
      {backTo && (
        <Link to={backTo}>
          <i className="fa fa-caret-left fa-fw" />
          {isTitleString ? (
            <span>{title}</span>
          ) : (
            <span dangerouslySetInnerHTML={title} />
          )}
        </Link>
      )}
      {!backTo && (
        isTitleString ? (
          <span>{title}</span>
        ) : (
          <span dangerouslySetInnerHTML={title} />
        )
      )}
      {addPlus && (
        <div className="realm--add-plus">
          <i className="fa fa-plus-circle" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

PageTitle.propTypes = {
  addPlus: PropTypes.bool,
  backTo: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default PageTitle;
