import React from 'react';
import { Link } from 'react-router-dom';
import localstring from '../services/Localization';
// import ContactUsDialog from './ContactUsDialog';

<div>
  handleClose
  <a href="1234.com">test</a>
</div>
const Footer = (props) => (
  <footer className="realm-footer">
    <div className="realm-footer--links">
      <a href="mailto:support+fromconciergesite@realm-global.com">{localstring.general_contact_us}</a>
      {/*
        <ContactUsDialog showText />
        <a href="">Language</a>
      */}
      <Link to="/company?page=terms-and-conditions">{localstring.footer_terms}</Link>
      <Link to="/company?page=privacy-policy">{localstring.footer_privacy}</Link>
      <Link to="/company?page=confidentiality-agreement">{localstring.footer_confidentiality}</Link>
      <Link to="/company?page=gdpr">{localstring.footer_cookies}</Link>
      <a href="http://dev.realm-global.com">{localstring.signin}</a>
    </div>
 
    <div className="realm-footer--copyright">
      &#169; {localstring.copyright}
      <div className="realm-footer--version">
        Version: {props.application.version}
    </div>
    </div>

  </footer>
);

export default Footer;
