import React from 'react';

const UnsupportedBrowser = () => {
  return(
  <div class="realm--page404">
    Unsupported Browser Error
    <br />
    <div className="realm--message404">
      We're sorry, but Realm does not support the browser you are using. Please
      use any one of the following browsers:
      <div className="realm--supported-browser-list">
        <p>Chrome</p>
        <p>Edge</p>
        <p>Firefox</p>
        <p>Internet Explorer 11</p>
        <p>Safari</p>
      </div>
    </div>
  </div>
)};

export default UnsupportedBrowser;
