import createReducer from './create-reducer';
import * as storage from '../persistence/storage';
import * as constants from '../constants';

// The initial state of the App
const initialState = {
  apiError: false,
  authenticationFailed: false,
  currentUser: JSON.parse(storage.get('me')) || null,
  errorMessage: '',
  isAuthenticated: Boolean(storage.get('token')) || false,
  loading: false,
  token: storage.get('token') || false
};

const actionHandlers = {
  [constants.AUTHENTICATE_PENDING]: () => ({ loading: true }),
  [constants.AUTHENTICATE_SUCCESS]: (_, action) => ({
    currentUser: action.payload.user,
    isAuthenticated: true,
    loading: false,
    token: action.payload.token
  }),
  [constants.AUTHENTICATE_REJECTED]: (_, action) => ({
    authenticationFailed: true,
    errorMessage: action.payload.message
      ? action.payload.message
      : JSON.stringify(action.payload),
    loading: false
  }),
  [constants.AUTHENTICATE_RETRY]: () => ({
    authenticationFailed: false,
    errorMessage: ''
  }),
  [constants.CLEAR_SESSION]: () => ({
    apiError: false,
    authenticationFailed: false,
    currentUser: null,
    errorMessage: '',
    isAuthenticated: false,
    loading: false,
    token: null
  })
};

export default createReducer(initialState, actionHandlers);
