import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Grid, withStyles } from '@material-ui/core';

import { API_ROOT } from '../constants';
import LoadingIndicator from './LoadingIndicator';
import defaultFeaturedImaged from '../images/img/defaultFeaturedImage.jpg';

const styles = {
  listPanel: {
    textAlign: 'left',
    padding: 8,
    marginBottom: 12,
    color: '#333',
    borderTop: '1px solid #474a6a'
  },
  category: {
    fontSize: 18,
    letterSpacing: '0.03em',
    fontWeight: 400,
    marginBottom: 12,
    fontFamily: '"Cormorant", serif',
    textTransform: 'uppercase'
  },
  blogTitle: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 50,
    fontWeight: 400,
    color: '#333'
  },
  blogList: {
    fontFamily: '"Roboto", sans-serif',
    marginLeft: 12
  },
  blogLink: {
    fontSize: 14,
    fontFamily: '"Roboto", sans-serif',
    letterSpacing: '0.075em',
    lineHeight: '12px',
    fontWeight: 300,
    marginBottom: 8
  },
  blogCard: {
    fontFamily: '"Roboto", sans-serif',
    backgroundColor: 'rgba(255,255,255, 0.8)',
    marginBottom: 16
  },
  blogCardLink: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 16,
    letterSpacing: '0.1em',
    lineHeight: '18px',
    fontWeight: 300,
    padding: 12
  },
  listImage: {
    background: `url(${defaultFeaturedImaged}) center center`,
    height: 280,
    backgroundSize: 'auto 166% !important',
    backgroundRepeat: 'no-repeat !important',
    transition: 'background-size 0.5s ease',
    '&:hover': {
      backgroundSize: 'auto 170% !important',
      transition: 'background-size 0.5s ease'
    }
  }
};

class BlogList extends Component {
  state = {
    isLoading: false,
    list: []
  };

  _isMounted = false;

  componentDidMount() {
    this.getContent();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getContent = async () => {
    this.setState({
      isLoading: true
    });

    //  get "cards" for list of blogs in specific categories
    // note: categories must be an array even it has only one element
    const response = await axios.get(`${API_ROOT}/cms/by-category`, {
      params: {
        categories: this.props.categories,
        view: this.props.view, // if 'card' then returns all the extra data for each list itme,
        count: this.props.count,
        language: this.props.language // must use language tag id for now.. en=4
      }
    });
    if (this._isMounted) {
      this.setState({
        list: response.data.data.data,
        isLoading: false
      });
    }
  };

  showBlogName(entry) {
    let name = entry.title.rendered;
    const firstBrace = name.indexOf('{') + 1;
    const lastBrace = name.lastIndexOf('}');
    name = name.substring(firstBrace, lastBrace);
    return name;
  }

  showFeaturedImage(entry) {
    const imageURL = entry.fifu_image_url;
    const imageAlt = entry.fifu_image_alt;
    return (
      <img
        src={imageURL}
        style={{ width: '100%', height: 'auto' }}
        alt={imageAlt}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <span>
        {this.props.grid ? (
          <div>
            {this.state.isLoading ? (
              <LoadingIndicator />
            ) : (
              <div style={{ height: 12 }} />
            )}
            <Grid container spacing={24}>
              {this.state.list.map((entry) => (
                <Grid item md={4} key={entry.slug}>
                  <div className={classes.blogCard}>
                    <Link to={`/blogpost?name=${entry.slug}`}>
                      {this.showFeaturedImage(entry)}
                      <div className={classes.blogCardLink}>
                        {this.showBlogName(entry)}
                      </div>
                    </Link>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <div className={classes.listPanel}>
            <div className={classes.category}> {this.props.title}</div>
            {this.state.isLoading ? (
              <LoadingIndicator />
            ) : (
              <div style={{ height: 12 }} />
            )}

            <div>
              {this.state.list.map((entry) => (
                <div key={entry.slug}>
                  {this.props.view === 'card' ? (
                    <div className={classes.blogCard}>
                      <Link to={`/blogpost?name=${entry.slug}`}>
                        {this.showFeaturedImage(entry)}
                        <div className={classes.blogCardLink}>
                          {this.showBlogName(entry)}
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div key={entry.slug} className={classes.blogList}>
                      <Link
                        to={`/blogpost?name=${entry.slug}`}
                        className={classes.blogLink}
                      >
                        {this.showBlogName(entry)} <br />
                        <br />
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </span>
    );
  }
}

BlogList.propTypes = {
  categories: PropTypes.array,
  count: PropTypes.number,
  classes: PropTypes.object,
  grid: PropTypes.bool,
  language: PropTypes.string,
  title: PropTypes.string,
  view: PropTypes.string
};

BlogList.defaultProps = {
  count: 5,
  language: 'en',
  title: 'Featured',
  view: 'list'
};

export default withStyles(styles)(BlogList);
