// API
export const API_ROOT_LOCAL = 'http://localhost:8010/api/v1';
export const API_ROOT_DEV = 'https://f9i8fscvgi.execute-api.us-east-1.amazonaws.com/dev/api/v1';
export const API_ROOT_STAGE = 'https://lkvhry3xr2.execute-api.us-east-1.amazonaws.com/stage/api/v1/';
export const API_ROOT_PROD = 'https://48dnz9g9w3.execute-api.us-east-1.amazonaws.com/prod/api/v1';
export const API_IMAGE_LOCAL = 'http://localhost:8015/';
export const API_IMAGE_DEV = 'https://dev.uservice.realm-global.com/';
export const API_IMAGE_STAGE = 'https://stage.uservice.realm-global.com/';
export const API_IMAGE_PROD = 'https://prod.uservice.realm-global.com/';
// export const USE_API_LOCAL = 0;
// export const USE_API_DEV = 1;
// export const USE_API_STAGE = 2;
// export const USE_API_PROD = 3;
export const API_URLS = {
  local: API_ROOT_LOCAL,
  dev: API_ROOT_DEV,
  staging: API_ROOT_STAGE,
  prod: API_ROOT_PROD
};
export const API_IMAGE_URLS = {
  local: API_IMAGE_LOCAL,
  dev: API_IMAGE_DEV,
  staging: API_IMAGE_STAGE,
  prod: API_IMAGE_PROD
};
export const ENV_COLORS = {
  local: '#000',
  dev: '#338',
  staging: 'rgb(177, 14, 188)',
  prod: '#88aa66'
};

export const API_ROOT = API_ROOT_LOCAL;

// Env
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

// App settings
export const INITIAL_WARNING_DURATION = 1000 * 60 * 60; // 60 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 70; // on 70 minutes, force logout
export const SEND_GRID_KEY = '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';

// Application actions constants
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const DEFAULT_LOCALE = 'en';
export const RESIZE_START = 'RESIZE_START';
export const RESIZE_END = 'RESIZE_END';
export const SCROLLING_START = 'SCROLLING_START';
export const SCROLLING_END = 'SCROLLING_END';
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SWITCH_DIRECTION = 'SWITCH_DIRECTION';
export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SWITCH_MEASURE = 'SWITCH_MEASURE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const WINDOW_ACTIVE = 'WINDOW_ACTIVE';
export const WINDOW_INACTIVE = 'WINDOW_INACTIVE';
export const SET_API_ENV = 'SET_API_ENV';

// Session actions constants
export const AUTHENTICATE_PENDING = 'AUTHENTICATE_PENDING';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_REJECTED = 'AUTHENTICATE_REJECTED';
export const AUTHENTICATE_RETRY = 'AUTHENTICATE_RETRY';
export const CLEAR_SESSION = 'CLEAR_SESSION';

// version in reducers/application.js

