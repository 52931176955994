import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { Button } from "@material-ui/core";
import { API_IMAGE_URLS } from "../../constants";
import moment from "moment";
import axios from "axios";
import LoadingIndicator from "../../components/LoadingIndicator";

const WeeklyReporting = (props) => {
  const [reportData, setReportData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userviceUrl = API_IMAGE_URLS[sessionStorage.getItem("currentAPIEnv")];
  const fetchData = async () => {
    setIsLoading(true);
    const reportResults = await axios.get(
      `${userviceUrl}info/concierge-weekly-report`,
      {
        headers: {
          "x-token": props.session.token,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    console.log(reportResults.data);
    setIsLoading(false);
    setReportData(reportResults.data);
    setDataFetched(true);
  };

  const insertCommasToNumber = (number) => {
    return number
      ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "N/A";
  };

  const expiringSubDisplay = () => {
    if (reportData.expiringSubs?.length > 0) {
      return reportData.expiringSubs.map((sub, index) => {
        reportData.expiringSubs.sort((a, b) => {
          return new Date(a.endsAt) - new Date(b.endsAt);
        });
        console.log(sub);
        return (
          <tr>
            <td style={{ paddingRight: 20 }}>
              {sub["Team ID"]
                ? sub["Team Name"] + " (team)"
                : sub["Agent Name"]}
            </td>
            <td style={{ paddingRight: 20 }}>
              {sub["Team ID"] ? sub["Team Brokerage"] : sub["Agent Brokerage"]}
            </td>
            <td style={{ paddingRight: 20 }}>
              {moment(sub["endsAt"]).format("LL")}
            </td>
          </tr>
        );
      });
    }
  };

  const displayActiveAgents = () => {
    if (reportData?.activeAgentList?.length > 0) {
      return reportData.activeAgentList.map((agent, index) => {
        return (
          <tr>
            <td style={{ paddingRight: 20 }}>{agent.name}</td>
            <td style={{ paddingRight: 20 }}>
              {moment(agent.lastLogin).format("LL")}
            </td>
          </tr>
        );
      });
    }
  };

  const displayExpiredActiveSubs = () => {
    if (reportData?.expiredActiveSubs?.length > 0) {
      //sort reportData.expiredActiveSubs by "endAt" date with oldest first
      reportData.expiredActiveSubs.sort((a, b) => {
        return new Date(a.endsAt) - new Date(b.endsAt);
      });

      return reportData.expiredActiveSubs.map((sub, index) => {
        if (!sub["Agent Name"] && !sub["Agent Brokerage"] && !sub["Team ID"]) {
          return;
        }
        console.log(sub);
        return (
          <tr>
            <td style={{ paddingRight: 20 }}>
              {sub["Team ID"]
                ? sub["Team Display Name"]
                  ? sub["Team Display Name"] + " (team)"
                  : sub["Team Name"] + " (team)"
                : sub["Agent Name"]}
            </td>
            <td style={{ paddingRight: 20 }}>
              {sub["Team ID"] ? sub["Team Brokerage"] : sub["Agent Brokerage"]}
            </td>
            <td style={{ paddingRight: 20 }}>
              {moment(sub["endsAt"]).format("LL")}
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <div className="realm--page-with-title" style={{ minHeight: "100vh" }}>
      <PageTitle title="Weekly Reporting" />
      <div style={{ marginTop: 30 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={dataFetched || isLoading}
          onClick={() => {
            fetchData();
          }}
          style={{ marginLeft: 24, marginBottom: 25 }}
        >
          Fetch weekly report data
        </Button>
        {isLoading && <LoadingIndicator />}
        {dataFetched && !isLoading && (
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 24 }}
          >
            <div style={{ paddingBottom: 8 }}>
              <b>Subscribed Members:</b>{" "}
              {insertCommasToNumber(reportData.subscribedAgents)}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>
                Active Members (Members who have signed in within the last 7
                days):
              </b>{" "}
              {insertCommasToNumber(reportData.activeUsers)}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Active Member Rate:</b>{" "}
              {`${Math.round(reportData.activeAgentRate)}%`}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Average Engagement Time:</b>{" "}
              <a
                href='https://analytics.google.com/analytics/web/#/p326680278/reports/dashboard?params=_u..nav%3Dmaui%26_u..comparisons%3D[{"name":"PROD - USERS","isEnabled":true,"filters":[{"fieldName":"audience","expressionList":["4"],"isCaseSensitive":true}]}]%26_u..built_comparisons_enabled%3Dfalse%26_u.dateOption%3Dlast7Days%26_u.comparisonOption%3Ddisabled&r=lifecycle-engagement-overview&collectionId=life-cycle'
                target="_blank"
                style={{ color: "blue" }}
              >
                {" "}
                View here
              </a>
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Listings:</b> {insertCommasToNumber(reportData.totalListings)}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>New Developments:</b> {reportData.totalDevelopments}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Clients:</b>{" "}
              {insertCommasToNumber(reportData.totalClientCount)}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Total High Matches:</b>{" "}
              {insertCommasToNumber(reportData.totalListingMatches)}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Wealth Events (past two weeks):</b>{" "}
              {insertCommasToNumber(reportData.totalWealthEvents)}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Brokerages:</b> {reportData.totalBrokerages}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Total Dollar Value in REALM:</b> {reportData.totalListingValue}
            </div>

            <div style={{ paddingBottom: 8 }}>
              <b>Active Agent List:</b>
              <table
                style={{ marginLeft: 35, marginTop: 35, marginBottom: 35 }}
              >
                <tr>
                  <th style={{ fontWeight: 10 }}>
                    <u>Member Name</u>
                  </th>
                  <th style={{ fontWeight: 10 }}>
                    <u>Last Sign In</u>
                  </th>
                </tr>
                {displayActiveAgents()}
              </table>
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Expired Manual Subscriptions Marked as Active:</b>
              {reportData.expiredActiveSubs?.length == 0 ? (
                <div style={{ paddingLeft: 30 }}>None</div>
              ) : (
                <table
                  style={{ marginLeft: 35, marginTop: 35, marginBottom: 35 }}
                >
                  <tr>
                    <th style={{ fontWeight: 10 }}>
                      <u>Member/Team Name</u>
                    </th>
                    <th style={{ fontWeight: 10 }}>
                      <u>Brokerage</u>
                    </th>
                    <th style={{ fontWeight: 10 }}>
                      <u>Expiration Date</u>
                    </th>
                  </tr>
                  {displayExpiredActiveSubs()}
                </table>
              )}
            </div>
            <div style={{ paddingBottom: 8 }}>
              <b>Manual Subscriptions Expiring in Next Week:</b>
              {reportData.expiringSubs?.length == 0 ? (
                <div style={{ paddingLeft: 30 }}>None</div>
              ) : (
                <table
                  style={{ marginLeft: 35, marginTop: 35, marginBottom: 35 }}
                >
                  <tr>
                    <th style={{ fontWeight: 10 }}>
                      <u>Member/Team Name</u>
                    </th>
                    <th style={{ fontWeight: 10 }}>
                      <u>Brokerage</u>
                    </th>
                    <th style={{ fontWeight: 10 }}>
                      <u>Expiration Date</u>
                    </th>
                  </tr>
                  {expiringSubDisplay()}
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyReporting;
