import {
  browserVersion,
  getUA,
  isAndroid,
  isWinPhone,
  isIOS,
  isIE,
  isMobile,
  isMobileOnly,
  isTablet,
  osVersion,
  osName
} from 'mobile-device-detect';
import * as storage from '../persistence/storage';
import hasStorage from '../utils/has-storage';
import createReducer from './create-reducer';
import {
  CLEAR_APPLICATION,
  SWITCH_CURRENCY,
  SWITCH_DIRECTION,
  SWITCH_LOCALE,
  SWITCH_MEASURE,
  TOGGLE_MENU,
  SET_API_ENV,
  API_ROOT_LOCAL,
  API_ROOT_PROD,
} from '../constants';

// The initial state of the App
const initialState = {
  browserVersion,
  currency: !!storage.get('currency') || 'USD',
  getUA,
  isAndroid,
  isWinPhone,
  isIOS,
  isIE,
  isLocalStorageEnabled: hasStorage,
  isMenuActive: false,
  isMobile,
  isMobileOnly,
  isTablet,
  isResizing: false,
  isRTL: !!storage.get('rtl') || false,
  isScrolling: false,
  isPushStateCompat: typeof window.history.pushState === 'function',
  isWindowActive: true,
  currentAPIEnvironment: document.URL.includes('localhost') ? 'local' : 'prod',
  apiRoot: storage.get('api_root') || (document.URL.includes('localhost') ? API_ROOT_LOCAL : API_ROOT_PROD),
  isLocalhost: document.URL.includes('localhost'),
  locale: storage.get('locale') || 'en',
  measure: storage.get('measure') || 'us',
  osVersion,
  osName,
  version: '2.0.0'
};

const actionHandlers = {
  [SWITCH_CURRENCY]: (_, action) => ({ currency: action.payload }),
  [SWITCH_DIRECTION]: (_, action) => ({ isRTL: action.payload }),
  [SWITCH_LOCALE]: (_, action) => ({ locale: action.payload }),
  [SWITCH_MEASURE]: (_, action) => ({ measure: action.payload }),
  [TOGGLE_MENU]: (_, action) => ({ isMenuActive: action.payload }),
  [SET_API_ENV]: (_, action) => ({ currentAPIEnvironment: action.payload.env, apiRoot: action.payload.newBaseUrl }),
  [CLEAR_APPLICATION]: () => ({
    locale: 'en',
    isRTL: false,
  }),
};

export default createReducer(initialState, actionHandlers);
