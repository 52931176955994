import React from 'react';
import { Route, Routes, Switch } from 'react-router-dom';

import Login from '../Login/Login';
import Home from '../Home';
import About from '../About';
import Blog from '../Blog';
import BlogPost from '../BlogPost';
import Company from '../Company';
// import Client from '../Client';
import Logout from '../Logout';
import Maintenance from '../Maintenance';
import NotFoundPage from '../NotFoundPage';
import HealthDashboard from '../../components/HealthDashboard';
import ToolEmails from '../../components/Tool-emails';
import EmailLogs from '../EmailLogs';
import WeeklyReporting from '../WeeklyReporting';
import Subscriptions from '../Subscriptions/Subscriptions';

const AppRoutes = (storeProps) => {

  const { session } = storeProps;
  console.log(session);

  if (session.token) {
    return (
    <Routes>
        <Route path="/" exact element={<HealthDashboard {...storeProps} />} />
        <Route path="/logout" element={<Logout {...storeProps} />} />
        <Route path="/test-emails" element={<ToolEmails {...storeProps} />} />
        <Route path="/email-logs" element={<EmailLogs {...storeProps} />} /> 
        <Route path='/weekly-reporting' element={<WeeklyReporting {...storeProps} />} />
        <Route path='/subscriptions' element={<Subscriptions {...storeProps} />} />
        {/* <Route exact path="/" render={(props) => <Home {...props} {...storeProps} />} /> */}
        <Route exact path="/home" render={(props) => <Home {...props} {...storeProps} />} />
        <Route path="/about" render={() => <About />} />
        <Route path="/blog" render={() => <Blog />} />
        <Route path="/blogpost" render={(props) => <BlogPost location={props.location} application={storeProps.application} />} />
        <Route path="/company" render={(props) => <Company location={props.location} application={storeProps.application} />} />
        {/* <Route path="/logout" render={(props) => <Logout history={props.history} actions={storeProps.actions} />} /> */}

        <Route path="/maintenance" render={() => <Maintenance />} />
        <Route path="" render={NotFoundPage} />
      </Routes>
    )
  } else {
    // not authenticated yet
    return (
      <Routes>
        <Route path="/" exact element={<Login {...storeProps} />} />
        <Route path="/logout" element={<Logout {...storeProps} />} />
        {/* <Route exact path="/" render={(props) => <Home {...props} {...storeProps} />} /> */}
        <Route exact path="/home" render={(props) => <Home {...props} {...storeProps} />} />
        <Route path="/about" render={() => <About />} />
        <Route path="/blog" render={() => <Blog />} />
        <Route path="/blogpost" render={(props) => <BlogPost location={props.location} application={storeProps.application} />} />
        <Route path="/company" render={(props) => <Company location={props.location} application={storeProps.application} />} />
        <Route path="/maintenance" render={() => <Maintenance />} />
        <Route path="" render={NotFoundPage} />
      </Routes>
    )
  }
};

export default AppRoutes;
